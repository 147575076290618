<template>
  <div class="review">
    <div class="card-header">Stage History by Citation</div>
    <kendo-datasource
      :ref="'citationSelectorDataSource'"
      :data="citationSelectorDataSource.data">
    </kendo-datasource>
    <kendo-datasource
      :ref="'citationStageHistoryDataSource'"
      :data="citationStageHistoryDataSource.data">
    </kendo-datasource>
    <div class ="card-body">
      <div>Citation:&nbsp;</div>
      <div>
        <kendo-dropdownlist
              class="StudyMappingDropdown"
              :id="'citationSelector'"
              :data-source-ref="'citationSelectorDataSource'"
              :data-value-field="'PublicationIdentity'"
              :data-text-field="'PublicationIdentity'"
              ></kendo-dropdownlist>
      </div>
    </div>
    <div class="card-body">
      <button class="k-button" style="height: 25px">Export To Excel</button>
    </div>
    <div>
      <kendo-grid id="history-by-citation-grid"
            :ref="'studyListGrid'"
            style="study-list-grid"
            :data-source="citationStageHistoryDataSource"
            :columns="columnsHistory"
            :selectable="'multiple row'"
            :sortable="sortable"
            :pageable="pageable"
            :groupable="groupable"
            :excel="excel"
            :scrollable="scrollable"
            :filterable="filterable">
      </kendo-grid>
    </div>
  </div>
</template>
<script>

import CitationStageHistoryDataSource from '@/assets/data/CitationStageHistory.json'
import CitationSelectorDataSource from '@/assets/data/Publication.json'

export default {
  name: 'review',
  data () {
    return {
      columnsHistory: [
        {
          title: 'Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID' },
            { field: 'Date', title: 'Date' },
            { field: 'CurrentStage', title: 'Current Stage' },
            { field: 'Reviewer1', title: 'Reviewer 1' },
            { field: 'Reviewer2', title: 'Reviewer 2' },
            { field: 'FinalReviewer', title: 'Final Reviewer' }
          ]
        }
      ],
      groupable: false,
      selectable: 'cell',
      sortable: true,
      scrollable: true,
      filterable: true,
      citationSelectorDataSource: CitationSelectorDataSource,
      citationStageHistoryDataSource: CitationStageHistoryDataSource
    }
  }
}
</script>
